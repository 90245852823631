import React from 'react';
import { Link } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import Layout from '../layouts';

export default (props) => (
  <Layout >
  <Row>
    <div style={{minHeight: '250px', marginTop: '100px'}}>
      <h1 style={{textAlign: 'center'}}>NOT FOUND</h1>
      <p style={{textAlign: 'center'}}>Sorry the page you are looking for does not exist. Why don't you try going back to <Link to="/">Home</Link>?</p>
    </div>
  </Row>
  </Layout>
)
